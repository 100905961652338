
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { computed, defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import TheLevelContent from '@/modules/membership/components/TheLevelContent.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import ThePointsItem from '@/modules/membership/components/ThePointsItem.vue'
import { Points, readPoints } from '@/modules/membership/member.api'
import state from '@/state'

export default defineComponent({
  name: 'LevelPoints',
  components: { ThePointsItem, Holder, List, TheLevelContent, Icon, PageWithHeader },
  setup () {
    const pointsList = ref<Array<Points> | null>(null)

    readPoints({ page: 1, pageCount: 10 })
      .then(resp => pointsList.value = resp as Array<Points>)

    return {
      pointsList,
      levelModel: computed(() => state.account.levelModel),
    }
  },
})
