
import { computed, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import state from '@/state'

export default defineComponent({
  name: 'TheLevelContent',
  components: { Icon },
  setup () {
    return {
      level: computed(() => state.account.levelModel.level),
      levelInfo: {
        header: [0, 1, 2, 3, 4],
        body: [
          {
            title: 'levelandpoints_6',
            value: ['$0', '$20', '$200', '$1000', '$5000'],
          }, {
            title: 'levelandpoints_7',
            value: ['——', '——', '5%', '10%', '20%'],
          }, {
            title: 'levelandpoints_8',
            value: ['——', '——', '——', '——', { i18nValue: 'levelandpoints_14' }],
          }, {
            title: 'levelandpoints_9',
            value: [
              { i18nValue: 'levelandpoints_10' },
              { i18nValue: 'levelandpoints_11' },
              { i18nValue: 'levelandpoints_11' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
            ],
          }, {
            title: 'levelandpoints_13',
            value: [
              '——',
              { i18nValue: 'levelandpoints_11' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
            ],
          },
          {
            title: 'levelandpoints_17',
            value: [
              '——',
              { i18nValue: 'levelandpoints_11' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
            ],
          },
          {
            title: 'levelandpoints_18',
            value: [
              '——',
              '——',
              '——',
              { i18nValue: 'levelandpoints_14' },
              { i18nValue: 'levelandpoints_14' },
            ],
          },
          {
            title: 'levelandpoints_19',
            value: ['——', '——', '$10', '$50', '$200'],
          },
          {
            title: 'levelandpoints_20',
            value: ['——', '3', '10', '30', '50'],
          },
          {
            title: 'levelandpoints_24',
            value: [
              '——',
              { i18nValue: 'levelandpoints_25' },
              { i18nValue: 'levelandpoints_25' },
              { i18nValue: 'levelandpoints_25' },
              { i18nValue: 'levelandpoints_25' },
            ],
          },
        ],
      },

    }
  },
})
