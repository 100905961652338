
import { defineComponent } from 'vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'ThePointsItem',
  components: { Time },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
})
